<template>
  <div>
    <!--begin::card-->
    <b-card
      class="card-custom gutter-b"
      no-body
    >
      <!--begin::card-header-->
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <b-card-title>
          <h2 class="card-label">
            {{ trans('logs.templates.deleted.details.title', { logId }) }}
            <span class="d-block text-muted pt-2 font-size-sm">
              {{ trans('logs.templates.deleted.details.desc') }}
            </span>
          </h2>
        </b-card-title>
      </div>
      <!--end::card-header-->

      <!--begin::card-body-->
      <div v-if="loading" class="card-body">
        <Loader
          :text="trans('logs.templates.deleted.loading')"
          loader-class="relative"
          loader-style="margin-right: 80px;"
        ></Loader>
      </div>
      <div v-else class="card-body">
        <div class="d-flex w-100">
          <!--begin::start-end-sync-->
          <div class="d-flex mr-10">
            <div class="mr-6">
              <span class="btn btn-sm btn-text btn-light-primary text-uppercase font-weight-bold">
                {{ momentFullDate(logEntry.createdOn) + '@' + momentHoursMinutesSeconds(logEntry.createdOn) }}
              </span>
            </div>
            <!--begin::completed-by-->
            <div
              v-if="logEntry.createdByName"
              class="d-flex align-items-center mb-0 font-weight-bold"
            >
              <i class="fad fa-user mr-2" style="font-size: 12px;"></i>
              <span>
                {{ logEntry.createdByName }}
              </span>
            </div>
            <!--end::completed-by-->
          </div>
          <!--end::start-end-sync-->
        </div>
      </div>
      <!--end::card-body-->
    </b-card>
    <!--end::card-->

    <b-card
      class="card-custom gutter-b"
      no-body
    >
      <!--begin::card-header-->
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <b-card-title>
          <h2 class="card-label">
            {{ trans('logs.templates.deleted.list.title') }}
            <span class="d-block text-muted pt-2 font-size-sm">
              {{ trans('logs.templates.deleted.list.desc') }}
            </span>
          </h2>
        </b-card-title>
      </div>
      <!--end::card-header-->
      <!--begin::items-loader-->
      <div v-if="loadingItems" class="card-body">
        <Loader
          :text="trans('logs.templates.deleted.loading')"
          loader-class="relative"
          loader-style="margin-right: 80px;"
        ></Loader>
      </div>
      <!--end::items-loader-->
      <!--begin::card-body-->
      <div v-else class="card-body" style="min-height: 80px;">
        <!--begin::items-list-->
        <div class="list-taskstyle">
          <div
            class="d-flex list-item"
            v-for="(item, key) in items"
            :key="'item-' + key"
          >
            <!--begin::content-->
            <div class="d-flex flex-column list-content">
              <div
                class="list-content-inline"
              >
                <div
                  class="
                  d-flex
                  rounded
                  p-4
                "
                  :class="item.bgClass"
                >
                  <!--begin--icon-->
                  <div
                    class="symbol symbol-40 mr-2"
                    :class="`symbol-${ item.iconBgClass }`"
                  >
                    <div class="symbol-label">
                      <i :class="`${ item.icon } text-${ item.iconClass }`"></i>
                    </div>
                  </div>
                  <!--end:icon-->
                  <!--begin::details-->
                  <div class="d-flex align-items-center justify-content-between w-100">
                    <!--begin::title and description-->
                    <div class="px-2">
                      <p class="text-dark font-weight-bold mb-1">
                        {{ item.title }}
                      </p>
                      <!--end::title-->

                      <!--begin::description-->
                      <p class="font-size-sm text-dark-50 mb-0">
                        {{ item.desc }}
                      </p>
                      <!--end::description-->
                    </div>
                    <!--end::title and description-->
                  </div>
                  <!--end::details-->
                </div>
              </div>
            </div>
            <!--end::content-->
          </div>
        </div>
        <!--end::items-list-->
      </div>
      <!--end::card-body-->
    </b-card>
  </div>
</template>

<script>
  import { computed, onBeforeMount, onMounted, ref } from '@vue/composition-api'
  import { SETTINGS } from '@src/store/settings'
  import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
  import { trans, transChoice } from '@src/core/helpers/translate'
  import { capitalize } from '@src/core/helpers/textUtils'
  import useDateTime from '@src/use/datetime'
  import * as objectPath from 'object-path'

  export default {
    name: 'LogsTemplatesDeleted',
    components: { Loader: () => import('@src/components/content/Loader') },
    setup (props, { root, refs }) {
      const fb = require('@src/firebaseConfig') // eslint-disable-line global-require
      const logId = ref(root.$route.params.logId)
      const logEntry = ref({})

      onBeforeMount(() => {
        root.$store.dispatch(SETTINGS.ACTIONS.SET_ACTIVE_SUBMENU, 'Dashboard')
      })

      onMounted(() => {
        root.$store.dispatch(SET_BREADCRUMB, [
          {
            title: trans('logs.index.title'),
            route: '/logs'
          },
          { title: capitalize(transChoice('global.template', 2)) },
          { title: trans('logs.templates.deleted.title') }
        ])
      })

      const { momentFullDate, momentHoursMinutesSeconds } = useDateTime()

      const listProps = ref({
        limit: 20,
        loading: true,
        loadingItems: true,
        orderBy: 'pos',
        sortOrder: 'asc',
        lastVisibleDoc: true,
        showMoreBtn: true,
        type: 'productsync'
      })

      const logEntryItems = ref([]) // Create placeholders while loading
      const loading = computed(() => !Object.keys(logEntry.value).length > 0)
      const loadingItems = computed(() => !logEntryItems.value.length > 0) // eslint-disable-line max-len

      const fetchLogItem = async () => {
        const getLogEntry = fb.functions.httpsCallable('backendLogsGetLogById')

        await getLogEntry({
          id: logId.value
        }).then((res) => {
          listProps.value.loading = false
          const logData = objectPath.get(res, 'data.result')
          const userName = objectPath.get(logData, 'data.authUser.name', false)
          if (userName) logData.createdByName = userName
          logEntry.value = logData
        }).catch((err) => {
          console.log('Error while fetching logs:', err)
        })

        return true
      }

      const loadMore = async (paginateDoc) => {
        const btn = refs.logitems_showmorebtn
        if (btn !== undefined) btn.classList.add('spinner', 'spinner-light', 'spinner-right', 'pr-14')

        const getLogItems = fb.functions.httpsCallable('backendLogsGetLogItems')

        return await getLogItems({
          data: {
            logId: logId.value,
            limit: listProps.value.limit,
            orderBy: listProps.value.orderBy, // disabled in backend
            sort: listProps.value.sortOrder,
            paginateDoc
          }
        }).then((res) => {
          if (listProps.value.loadingItems) logEntryItems.value = []
          listProps.value.showMoreBtn = Object.keys(objectPath.get(res, 'data.result', {})).length >= listProps.value.limit // eslint-disable-line max-len

          Object.keys(objectPath.get(res, 'data.result', {})).forEach((doc) => {
            logEntryItems.value.push(res.data.result[doc])
          })

          if (btn !== undefined) btn.classList.remove('spinner', 'spinner-light', 'spinner-right', 'pr-14')
          listProps.value.lastVisibleDoc = objectPath.get(res, 'data.paginateDoc.lastVisibleDoc', true)
        })
      }

      fetchLogItem()
      loadMore(true).then(() => {
        listProps.value.loadingItems = false
      })

      const logItemStyles = {
        missing: {
          bgClass: 'bg-light',
          iconBgClass: 'light-primary',
          iconClass: 'primary',
          icon: 'fal fa-trash'
        },
        deletedtemplateimage: {
          bgClass: 'bg-light',
          iconBgClass: 'light-primary',
          iconClass: 'primary',
          icon: 'fal fa-trash'
        },
        deletedstyletypeimage: {
          bgClass: 'bg-light',
          iconBgClass: 'light-primary',
          iconClass: 'primary',
          icon: 'fal fa-trash'
        }
      }

      const items = computed(() => logEntryItems.value.map((entry) => {
        let desc = ''

        if (objectPath.get(entry, 'type', 'missing') === 'deletedtemplateimage') {
          desc = trans('logs.index.items.labels.deletedtemplateimage.desc', {
            templatename: objectPath.get(entry, 'data.template.name', trans('logs.index.items.labels.deletedtemplateimage.unknownTemplate')) // eslint-disable-line max-len
          })
        } else if (objectPath.get(entry, 'type', 'missing') === 'deletedstyletypeimage') {
          desc = trans('logs.index.items.labels.deletedstyletypeimage.desc', {
            styletypename: objectPath.get(entry, 'data.task.data.styletype.name', trans('logs.index.items.labels.deletedstyletypeimage.unknownStyletype')), // eslint-disable-line max-len
            templatename: objectPath.get(entry, 'data.template.name', trans('logs.index.items.labels.deletedstyletypeimage.unknownTemplate')) // eslint-disable-line max-len
          })
        } else {
          desc = trans(`logs.index.items.labels.${ objectPath.get(entry, 'type', 'missing') }.desc`)
        }

        return {
          createdOn: objectPath.get(entry, 'createdOn', {}),
          title: trans(`logs.index.items.labels.${objectPath.get(entry, 'type', 'missing')}.title`),
          desc,
          bgClass: logItemStyles[objectPath.get(entry, 'type', 'missing')].bgClass,
          iconBgClass: logItemStyles[objectPath.get(entry, 'type', 'missing')].iconBgClass,
          iconClass: logItemStyles[objectPath.get(entry, 'type', 'missing')].iconClass,
          icon: logItemStyles[objectPath.get(entry, 'type', 'missing')].icon,
          variant: objectPath.get(entry, 'variant', 'info')
        }
      }))

      return {
        trans,
        transChoice,
        capitalize,
        momentFullDate,
        momentHoursMinutesSeconds,
        loading,
        loadingItems,
        // Main logentry
        logId,
        logEntry,
        // List items
        listProps,
        items
      }
    }
  }
</script>

<style lang="scss">

</style>
